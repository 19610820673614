import React from 'react'
import I18n from '../../config/locale'
import styled from 'styled-components'
import { Title, LoginBtn, Link, Space } from '../atoms'
import { inject, observer } from 'mobx-react'
import LoginModel from '../../models/Login'

const LoginLine = styled.div`
min-height: 300px;
display: flex;
padding: 12px;
justify-content: center;
`

const LoginBox = styled.div`
box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px;
border-radius: 7px;
background-color: white;
min-width: 250px;
max-width: 400px;
display: flex;
justify-content: center;
padding: 24px;
text-align: center;
flex-direction: column;


`

const LoginBoxContent = styled.div`
display: flex;
flex-direction: column;
align-items: center;
flex: 1;
`

const LoginBoxFooter = styled.div`
  display: flex;
`

interface IProps {
  login?: LoginModel
}
@inject('login')
@observer
export default class Login extends React.Component<IProps, {}>{

  clickLogin = () => {
    console.log('login clicked', this.props.login.getLoginURI())
    window.location.href = this.props.login.getLoginURI()
  }

  renderMainContent() {
    return (
      <LoginBox>
        <LoginBoxContent>
          <Title >{I18n.t('startPage.headerText')}</Title>
          <LoginBtn
          onClick={this.clickLogin}>{I18n.t('startPage.login')}</LoginBtn>
          <Space/>
          <Link target="_blank" href={I18n.t('startPage.tutorialURL')}>{I18n.t('startPage.helpLink')}</Link>

        </LoginBoxContent>
        <LoginBoxFooter>
            <Link target="_blank" href={I18n.t('startPage.dataprotectURL')}>{I18n.t('startPage.dataprotect')}</Link>
          </LoginBoxFooter>
      </LoginBox>
    )
  }
  render() {

    return (
      <LoginLine>
      {this.renderMainContent()}
      </LoginLine>
    )
  }
}
