import React from 'react'
import { inject, observer } from 'mobx-react'
import UserModel from '../models/UserHandler'
import LoginModel from '../models/Login'
import styled from 'styled-components'

const IFrame = styled.iframe`
   width:0;
   height:0;
   border:0;
   border:none;
   display: none;

`
interface IProps {
  user?: UserModel
  login?: LoginModel

}

@inject('user', 'login')
@observer
export default class RefreshHandler extends React.Component < IProps, {} > {

  componentWillReceiveProps(newProps: IProps) {

  }

  render() {
    if (this.props.user.triggerRefreshToken) {
      setTimeout(() => {
        this.props.user.loadDataFromCookie()

      },         2000)
      return <IFrame src={this.props.login.getRefreshURI()}/>
    }
    return null
  }
}
