import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'mobx-react'

import Slides from './models/Slides'
import FileUp from './models/FileUpload'
import Login from './models/Login'
import User from './models/UserHandler'
import Party from './models/PartyHandler'
import PayHandler from './models/PayHandler'
import Cart from './models/Cart'
const models = {
  slide: new Slides(),
  fileupload: new FileUp(),
  login: new Login(),
  user: new User(),
  party: new Party(),
  pay: new PayHandler(),
  cart : new Cart(),

}

ReactDOM.render(<Provider {...models} ><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
