import React from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent, { DialogContentProps } from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import I18n from '../../config/locale'
import { PriorBtn, Title } from '../atoms'
import { PayType } from '../../models/Cart'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PayHandler from '../../models/PayHandler'
import { inject, observer } from 'mobx-react'

interface IProps{
  open :boolean

  pay?: PayHandler
  closeClicked: () => void
  addToCartClicked: (paytype: PayType) => void
}

interface IState{
  storageSelected : number
}

const RootDiv = styled.div`
`
const PriceDiv = styled.div`
text-align: right;
text-decoration: underline;
`
const MyDialog = styled(Dialog as React.SFC<DialogProps>)`
 &&{
  overflow-y: hidden;
 }
`

const MyDialogContent = styled(DialogContent as React.SFC<DialogContentProps>)`
&&{
  overflow-y: hidden;
}

`

@inject('pay')
@observer
export default class PayDialog extends React.Component<IProps, IState>{
  constructor(props: IProps) {
    super(props)
    this.state = {
      storageSelected: 2,
    }
  }

  componentWillMount() {
    this.props.pay.loadPayInfo()

  }

  addToCartClicked = () => {
    const type = this.props.pay.payInfos[this.state.storageSelected].type
    this.props.addToCartClicked(type)
    this.props.closeClicked()
  }

  handleStorageSelectChange = (event: any, value: React.ReactElement<HTMLSelectElement>) => {
    this.setState({
      storageSelected: parseInt(value.props.value, 0),
    })
  }

  renderPrice() {
    return (
      <PriceDiv>
       <Title> Preis: {this.props.pay.payInfos[this.state.storageSelected].price.price} {this.props.pay.payInfos[this.state.storageSelected].price.currency}</Title>
       </PriceDiv>
    )
  }
  renderContent() {
    if (this.props.pay.payInfos === null) {
      return null
    }

    const menuItem = this.props.pay.payInfos.map((one, index) => {
      return  <MenuItem key={'paytype' + index} value={index}>{one.humenDesc}</MenuItem>
    })

    return (
      <RootDiv>
      <Select
            value={this.state.storageSelected}
            onChange={this.handleStorageSelectChange}
          >
          {menuItem}
      </Select>
      {this.renderPrice()}
    </RootDiv>
    )
  }

  render() {
    return (
      <MyDialog
      open={this.props.open}>
    <DialogTitle>
      {I18n.t('partyPagePayDialog.title')}
    </DialogTitle>
    <MyDialogContent>
      <DialogContentText>
        {I18n.t('partyPagePayDialog.description')}
      </DialogContentText>
      {this.renderContent()}
    </MyDialogContent>
    <DialogActions>
      <PriorBtn onClick={this.addToCartClicked}>{I18n.t('partyPagePayDialog.addToCart')}</PriorBtn>
      <PriorBtn onClick={this.props.closeClicked}>{I18n.t('partyPagePayDialog.cancel')}</PriorBtn>
    </DialogActions>
  </MyDialog>
    )
  }
}
