import React from 'react'
import  FileUpload from './FileUpload'
import { getParty } from '../utils/helper'

interface IProps {
}

export class FileUploadPage extends React.Component<IProps, {}> {

  render() {
    return (
      <FileUpload id={getParty()} />
    )
  }
}
