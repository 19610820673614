import gql from 'graphql-tag'
const Fragments = {
  parties: `
  fragment parties on Party{
    ID
    name
    folderSize
    maxSize
    expireDate
  }
  `,
  userInfo: `
    fragment userInfo on User {
      maxCountParties
      countParties
      readDataProtect{
        checked
      }
      allParty : parties{
        ...parties
      }
    }
  `,

}

export const Subscribtions = {
  getSubscribeParty: gql`
  subscription($id: String!){
  imageUploaded(partyid: $id){
    url
    width
    height
  }
}
  `,
}

export const Queries = {
  payInfo: gql`
  {
  payInfo {
    type
    humenDesc
    price {
      price
      currency
    }
  }
}
  `,
  cartURL: gql`
  {
  cartUrl
}
  `,
  cartSize: gql`
    {
  cartSize
}
  `,
  getPartyById: gql`
 query party($id: String!){
  oneparty:party(id: $id){
    ID
    name
    images{
      url
      width
      height
    }
  }
}
  `,

  getAllParties: gql`
  {
  UserInfo{
    ...userInfo
  }
}
${Fragments.userInfo}
${Fragments.parties}

`,
}

export const Mutations = {

  payProdukt: gql`
  mutation($partyID: String!, $payType: PayType!){
  payProduct(payInfo: {
    partyID: $partyID
    payType: $payType
  })
}
  `,

  requiredDocumentsRead: gql`
    mutation{
    UserInfo: SetDataProtectRead{
    ...userInfo
  }
}
${Fragments.userInfo}
${Fragments.parties}
  `,

  deleteParty: gql`
  mutation($id: String!){
  allParty: deleteParty(id: $id){
    ...parties
  }
}
${Fragments.parties}
  `,
  addParty : gql`
  mutation($name: String!){
    allParty: addParty(name: $name){
    ...parties
  }
}
  ${Fragments.parties}
  `,

}
