import React from 'react'
import { inject, observer } from 'mobx-react'
import MySlider from './react-slider/MySlider'
import styled from 'styled-components'
import { getParty } from '../utils/helper'
import PartyHandler from '../models/PartyHandler'
const MyImage = styled.div<{background: string}>`
background-image:url('${props => props.background}');
position: absolute;
top:0;
right: 0;
left: 0;
bottom: 0;
background-repeat: no-repeat;
background-size: contain;
background-position: 50%;
`

interface IProps {
  party?: PartyHandler
}

@inject('party')
@observer
export class SlideShow extends React.Component<IProps, {}> {
  partysubscriber : ZenObservable.Subscription = null
  componentWillMount() {
    const id = getParty()
    this.props.party.loadPartyById(id)
    this.partysubscriber = this.props.party.subscribeParty(id)
  }

  componentWillUnmount() {
    this.partysubscriber.unsubscribe()
  }

  getSliderProps() {
    return {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true,
    }
  }

  getSlideingImage() {

    return this.props.party.exactParty.images.map((one, key) => {
      return (
        <MyImage key={'myimage' + one} background= {one.url}/>
      )

    })
  }

  render() {
    if (this.props.party.exactParty == null) {
      return null
    }
    return (
      <div>

        <MySlider
          slideElements={this.getSlideingImage()}
          slideInterval={4000}
          />
      </div>
    )
  }
}
