
import  React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Button , { ButtonProps } from '@material-ui/core/Button'

interface Props{
}

export const Link = styled.a`
  color: #000000;
  :hover {
    color: #9E9E9E
  }
`

export const Space = styled.div`
  margin: 6px;
`
export const HalfSpace = styled.div`
  margin: 3px;
`

export const StyledButton = styled(Button as React.SFC<Props>)`
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
  border-radius: 4px;
  border: 0;
  color: white;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
`
interface TextProps {
  error ? : boolean
}

export const Text = styled.span<TextProps>`
  padding: 3px;
  ${props => props.error ? `
  border: 1px solid red
  ` : '' }
`

export const TextBlock = styled.p``

export const TextCircle = styled.div`
  width: 50px;
  height: 50px;
  border_radius: 100 %;
  text-align: center;
  margin: 0 auto;

`

export const Hr = styled.hr``

export const Title = styled(Typography as React.SFC<Props>)`
&& {
  font-family: "Roboto script=all rev=1";
  font-weight: 1000;
  font-style: normal;
  font-size: 32px;
  padding: 3px;
  margin: 3px;
}

`

export const LoginBtn = styled(Button as React.SFC<ButtonProps>)`
&& {border: 0px;
height: 36px;
display: inline-block;
border-radius: 4px;
padding: 0px 18px;
font-size: 15px;
font-weight: bold;
outline: none;
box-shadow: none;
cursor: pointer;
margin-top: 10px;
vertical-align: middle;
text-align: center;
background-color: #FF8F00;
color: rgb(255, 255, 255);
width: 80%;
:hover{
  background-color: #FF6F00;
}
}
`

export const PriorBtn = styled(Button as React.SFC<ButtonProps>)`
&& {border: 0px;
margin: 6px;
height: 36px;
display: inline-block;
border-radius: 4px;
padding: 0px 18px;
font-size: 15px;
font-weight: bold;
outline: none;
box-shadow: none;
cursor: pointer;
margin-top: 10px;
vertical-align: middle;
text-align: center;
background-color: #FF8F00;
color: rgb(255, 255, 255);
:hover{
  background-color: #FF6F00;
}
}
`

export const LongText = styled(Typography as React.SFC<Props>)`
&& {
  padding: 6px;
  font-family: "Montserrat script=all rev=2";
  font-weight: 400;
  font-style: normal;
}


`
