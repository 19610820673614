import React from 'react'

interface IProps {

}

export default class ErrorPage extends React.Component<IProps, {}> {

  render() {
    return (
      <div>Seite nicht gefunden überprüfe nochmal deine URL ...</div>
    )
  }

}
