import React from 'react'
import { inject, observer } from 'mobx-react'
import UserHandler from '../../models/UserHandler'
import PartyHandler from '../../models/PartyHandler'
import { LongText, Title, PriorBtn, Hr } from '../atoms'
import { History } from 'history'
import Header from '../Header'
import I18n from '../../config/locale'
import styled from 'styled-components'
import Party from './Party'
import NewPartyDialog, { AllPartieInUseDialog } from './NewPartyDialog'
import { RequiredDocumentsReadDialog } from '../RequiredDocumentsReadDialog'
const Content = styled.div`
  display: flex;
  flex-direction: column;
`
const SubHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`

const PartyElementsWrapper = styled.div``

interface IProps {
  user?: UserHandler
  party?: PartyHandler
  history?: History
}

type State = {
  openNewPartyDialog : boolean,
  openAllPartyInUseDialog: boolean,
}

@inject('user', 'party')
@observer
export default class Home extends React.Component<IProps, State>{

  constructor(props: IProps) {
    super(props)
    this.state = {
      openNewPartyDialog: false,
      openAllPartyInUseDialog: false,
    }
  }

  getAccessToken():string {
    const hash = window.location.hash
    const values = hash.substr(1).split('&')
    type HashData = {
      [key: string]: string,
    }
    const data: HashData = {}
    for (const one in values) {
      const value = values[one]
      const v = value.split('=')
      data[v[0]] = v[1]
    }
    return data.access_token
  }

  componentWillMount() {
    if (this.props.party !== undefined){
      this.props.party.loadAllParties()
    }
  }

  newPartyClicked = () => {

    if (this.props.party !== undefined && this.props.party.userInfo.countParties === 0) {
      this.setState({
        openAllPartyInUseDialog: true,
      })
    } else {
      this.setState({
        openNewPartyDialog: true,
      })
    }
  }

  newPartyDialogClosedClicked = () => {
    this.setState({
      openNewPartyDialog: false,
    })
  }
  newPartyDialogSubmitClicked = (name : string) => {
    if (this.props.party !== undefined){
      this.props.party.addNewParty(name)
      this.newPartyDialogClosedClicked()
    }
  }
  openParty = (ID: string) => {
    if (this.props.history !== undefined) {
      this.props.history.push('/home/party', {
        id: ID,
      })
    }
  }

  onClickDeleteParty = (ID: string) => {
    
    this.props.party.deleteParty(ID)
  }

  renderParties = () => {
    if (this.props.party.all.length === 0) {
      return <LongText>{I18n.t('home.noParties')}</LongText>
    }
    return this.props.party.all.map((one, k) => {
      return <Party onClickDelete={this.onClickDeleteParty} onClick={this.openParty} key={'partie' + one.ID} party={one} />
    })
  }

  requiredDocumentsReadDialogClicked = () => {
    this.props.party.setRequiredDocumentsRead()
  }

  render() {

    if (this.props.user.user === null) {
      this.props.history.push('/')
    }
    if (this.props.party.userInfo == null) {
      return null
    }

    const userSalutation = this.props.user.user !== null ? this.props.user.user.name : null
    return (
      <>
      <Header
      onLogoutClicked={() => {
        this.props.user.logout()
      }}
      title={I18n.t('home.title') + userSalutation}/>
      <Content >
        <SubHeader>
          <Title>{I18n.t('home.myPartys')}</Title>
          <PriorBtn onClick={this.newPartyClicked} >{I18n.t('home.newPartyBtn')} ({this.props.party.userInfo.maxCountParties - this.props.party.userInfo.countParties}/{this.props.party.userInfo.maxCountParties})</PriorBtn>
        </SubHeader>
        <Hr />
        <PartyElementsWrapper>
          {this.renderParties()}

        </PartyElementsWrapper>
      </Content>
      <NewPartyDialog
        open={this.state.openNewPartyDialog}
        closeClicked={this.newPartyDialogClosedClicked}
        submitClicked={this.newPartyDialogSubmitClicked}
        />
        <AllPartieInUseDialog
        open={this.state.openAllPartyInUseDialog}
        closeClicked={() => {
          this.setState({
            openAllPartyInUseDialog: false,
          })
        }}/>
        <RequiredDocumentsReadDialog open={!this.props.party.userInfo.readDataProtect.checked} submitClicked={this.requiredDocumentsReadDialogClicked}/>
    </>
    )
  }
}
