import React from 'react'
import styled from 'styled-components'

const RootDiv = styled.div`
width: 98vw;
height: 98vh;
background: #000;
position: relative;
`

const SlideShowSlides = styled.ul`
list-style: none;
margin: 0;
padding: 0;
`

const OneSlide = styled.li< {active: boolean}>`
position: absolute;
top: 0; right: 0; bottom: 0; left: 0;
margin: 0;
padding: 0;
transition: .2s;
${props => props.active ? `
opacity: 1;
` :`
opacity: 0;
`}

`

interface IProps {
  slideElements: JSX.Element[]
  slideInterval: number
}

export default class MySlider extends React.Component<IProps, {}>{
  state: {
    index: number,
    interval: NodeJS.Timeout,
  }
  constructor(props: IProps) {
    super(props)
    this.state = {
      index: 0,
      interval: null,
    }
  }
  componentDidMount() {
    const interval = setInterval(() => {
      let newIndex = this.state.index + 1
      if (this.state.index === this.props.slideElements.length - 1) {
        newIndex = 0
      }
      this.setState({
        index: newIndex,
      })
    },                           this.props.slideInterval)
    this.setState({
      interval,
    })
  }
  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  render() {
    return (
      <RootDiv>
        <SlideShowSlides>
        {this.renderSlideElements()}

        </SlideShowSlides>
      </RootDiv>
    )
  }

  renderSlideElements() {

    return this.props.slideElements.map((one, key) => {
      if (key === this.state.index || key === this.state.index + 1) {
        return (
          <OneSlide key={'oneslide' + key} active={key === this.state.index}>
            {one}
          </OneSlide>
        )
      }
      return null

    })
  }

}
