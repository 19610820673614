import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { PriorBtn } from '../atoms'
import I18n from '../../config/locale'
import { inject, observer } from 'mobx-react'

import Cart from '../../models/Cart'

interface IProps {
  cart ? : Cart,
}

@inject('cart')
@observer
export default class ProductAddedToCart extends Component<IProps, {} > {

  goToCart = () => {
    this.props.cart.getPaymentRedirectUrl().then((url) => {
      window.location.href = url
    })
    this.close()
  }

  close = () => {
    this.props.cart.resetProductAddedToCart()
  }

  render() {
    return(
    <Dialog open={this.props.cart.productAddedToCart}>
      <DialogTitle>{I18n.t('partyPagePayedDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{I18n.t('partyPagePayedDialog.content')}</DialogContentText>
      </DialogContent>
      <DialogActions>
      <PriorBtn onClick={this.goToCart}>{I18n.t('partyPagePayedDialog.goToCart')}</PriorBtn>
      <PriorBtn onClick={this.close}>{I18n.t('partyPagePayedDialog.closeDialog')}</PriorBtn>
      </DialogActions>
    </Dialog>
    )
  }
}
