import React from 'react'
import { PriorBtn } from '../atoms'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import I18n from '../../config/locale'

interface IProps {
  open :boolean
  closeClicked: () => void
  submitClicked: (name: string) => void
}

type State = {
  textFieldText: string,

}

interface AllPartyUserProps {
  open :boolean
  closeClicked: () => void
}
export class AllPartieInUseDialog extends React.Component<AllPartyUserProps, {}>{
  render() {
    return (
      <Dialog open={this.props.open} >
    <DialogTitle>
      {I18n.t('allPartyInUseDialog.title')}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {I18n.t('allPartyInUseDialog.description')}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <PriorBtn onClick={this.props.closeClicked}>{I18n.t('allPartyInUseDialog.close')}</PriorBtn>
    </DialogActions>
  </Dialog>
    )

  }
}

export default class NewPartyDialog extends React.Component<IProps, {} > {

  state : State = {
    textFieldText: '',
  }
  onTextFieldChange = (event: any) => {
    this.setState({
      textFieldText:event.target.value,
    })
  }

  onSubmitClicked = () => {
    return this.props.submitClicked(this.state.textFieldText)
  }
  render() {
    return (
    <Dialog open={this.props.open} >
      <DialogTitle>
        {I18n.t('newPartyDialog.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {I18n.t('newPartyDialog.description')}
        </DialogContentText>
        <TextField autoFocus onKeyPress={(e) => {
          if (e.charCode === 13) {
            this.onSubmitClicked()
          }
        }} onChange={this.onTextFieldChange} label={I18n.t('newPartyDialog.textfieldLabel')}/>
      </DialogContent>
      <DialogActions>
        <PriorBtn onClick={this.props.closeClicked}>{I18n.t('newPartyDialog.close')}</PriorBtn>
        <PriorBtn onClick={this.onSubmitClicked}>{I18n.t('newPartyDialog.submit')}</PriorBtn>
      </DialogActions>
    </Dialog>
    )
  }

}
