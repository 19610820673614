const de = {
  header:{
    openProfile: 'Profileinstellungen',
    logoutTooltip: 'Abmelden',
    shoppingcartTooltip: 'Warenkorb',
  },
  partyPagePayDialog: {
    title:'Zusatzspeicher Kaufen',
    description: 'Hier kannst du weiteren Speicher für dieses Event kaufen um mehr Bilder hochladen zu können.',
    addToCart: 'Zum Warenkorb hinzufügen',
    cancel: 'Doch nicht',
  },
  partyPagePayedDialog: {
    title: 'Das Produkt wurde in den Warenkorb gelegt',
    content: 'Es wird für Sie freigeschaltet sobald Sie den kauf Abgeschlossen haben',
    goToCart: 'Zum Warenkorb',
    closeDialog: 'Ich möchte Später bestellen',
  },
  partyPage: {
    payBtn: 'Weitern Speicher dazukaufen',
    title: 'Party: ',
    linksDescription: 'Hier findest du alle Links, die du mit deinen Freuden Teilen kannst.',
    fileUploadTabTitle: 'Bilder hochladen',
    downloadTabTitle:'Bilder herrunterladen',
    slideshowTabTitle:'Bilderslideshow',
    imagePreviewTitle: 'Vorschau',
    imagePreviewSubTitle: 'Alle bisher hochgeladenen Bilder',
  },

  shareLinks: {
    clipboardTooltip: 'In die Zwischenablage kopieren',
    clipboardTooltipCopied: 'Kopiert!',
    openInNewTab: 'Link in neuen Tab öffnen',
  },

  startPage: {
    tutorialURL: 'https://docs.fasibio.de/photomedia/Wie_funktionierts/',
    dataprotectURL: 'https://docs.fasibio.de/photomedia/Datenschutzerklaerung/',
    dataprotect: 'Datenschutz',
    dataprotection: 'Datenschutzerklärung',

    title: 'Willkommen auf Photomedia',
    email: 'Email',
    password: 'Passwort',
    login: 'Anmelden oder Registrieren',
    helpLink: 'So gehts!',
    headerText: 'Registrier dich um Partybilder zu sammeln',
    successLogin: 'YUHU Sie wurden erfolgreich angemeldet!',
    forgotPassword: 'Passwort vergessen ?',
  },
  newPartyDialog: {
    title: 'Neue Party anlegen',
    textfieldLabel: 'Den Name deiner Party',
    submit: 'Erstellen',
    close: 'Abbruch',
    description: 'Hier kannst du eine neue Party eröffnen. In diese können dann deine Gäste Ihre Bilder hochladen',
  },
  allPartyInUseDialog: {
    description: 'Die maximale Anzahl an anlegbaren Parties ist erreicht. Lösche eine bestehende Party, um eine neue Anlegen zu können',
    title: 'Alle verfügbaren Parties sind in Verwendung',
    close: 'Verstanden',

  },

  requiredDocumentsReadDialog: {
    title: 'AGB / Datenschutz / Nutzungsvereinbarung',
    description: 'Wie benötigen von Ihnen eine Bestätigung, das Sie die alle folgenden Betreffende Dokumente gelesen und verstanden haben',
    descriptionDataProtectCheckbox1: 'Ich habe die ',
    descriptionDataProtectCheckbox2:' gelesen und verstanden',
    submit: 'Abschicken',
  },

  home: {
    expiredTime:'existiert bis ',
    title: 'Hallo ',
    myPartys: 'Meine Parties',
    newPartyBtn: 'Neue Party erstellen',
    noParties: 'No keine Party angelegt ... ',
  },

  fileupload: {
    error423: 'Der Speicher dieses Events ist voll ! die Bilder können nicht mehr hogeladen werden. Wir schicken eine Nachricht an den Ersteller.',
    desc1: 'Hier können mit Klick auf das Viereck eins oder mehrere Bilder Hochgeladen werden.',
    desc2: 'Oder die Bilder einfach auf das Viereck per Drag and Drop ziehen',
    uploadFinish: 'Upload beendet',
    uploadingText: 'Bitte warten:',
  },
}
export default de
