import { observable, action, runInAction } from 'mobx'
import { Queries, Mutations } from '../config/GraphqlQueries'
import { getQueryResult, getMutationResult } from '../config/apolloSettings'
export enum PayType {
  PartySpace1= 'PartySpace1',
  PartySpace2 = 'PartySpace2',
  PartySpace3 = 'PartySpace3',
  }
export default class Cart {
  @observable size : number = 0

  @observable productAddedToCart = false
  constructor() {
    this.loadCartSize()
  }

  @action
  resetProductAddedToCart() {
    this.productAddedToCart = false
  }

  @action
  getPaymentRedirectUrl(): Promise<string> {
    return getQueryResult<{cartUrl: string}>(Queries.cartURL).then((d) => {
      return d.data.cartUrl
    })
  }

  @action
  payProduct(params: { partyID: string, payType : PayType }) {
    // TODO better result handling
    getMutationResult<{payProduct: Boolean}>(Mutations.payProdukt, params).then((d) => {
      if (d.data.payProduct) {
        runInAction(() => {
          this.productAddedToCart = true
        })
      }

    })
  }

  @action
  loadCartSize() {
    getQueryResult<{cartSize: number}>(Queries.cartSize).then((d) => {
      runInAction(() => {
        this.size = d.data.cartSize
      })
    })
  }

}
