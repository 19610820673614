import React from 'react'
import { SlideShow } from './components/SlideShow'
import { FileUploadPage } from './components/FileUploadPage'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import StartPage from './components/startPage/index'
import ErrorPage from './components/ErrorPage'
import HomePage from './components/home/index'
import RefreshHandler from './components/RefreshHandler'
import PartyPage from './components/partyPage/Index'

interface IProps {

}

// 1DkTzsXOCKUva2BFxUwZ36OQa5o
class MyRoute extends React.Component<IProps, {}>{
  render() {
    return (
      <>
      <RefreshHandler />
      <Router>

        <Switch>
          <Route exact path="/" component={StartPage} />
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/home/party" component={PartyPage} />
          <Route path="/fileupload" component={FileUploadPage} />
          <Route path="/slideshow" component={SlideShow} />
          <Route component={ErrorPage} />
        </Switch>
      </Router>
      </>
    )
  }
}
export default MyRoute
