import { observable, action, runInAction } from 'mobx'
import { getQueryResult , getMutationResult, getSubscribeResult } from '../config/apolloSettings'
import { Queries, Mutations, Subscribtions } from '../config/GraphqlQueries'
import { getImageHandlerURL} from '../utils/helper'
export interface Data {
  allParty: AllParty[]
}

export interface AllParty {
  ID:   string
  name: string
  folderSize: number
  maxSize: number
  expireDate: number
}
export interface OnePartyData {
  oneparty: Oneparty
}

export interface Oneparty {
  ID:        string
  name:      string
  images : Image[]
  imagePath: string[]
}

export interface Image {
  url: string
  width: string
  height: string

}

export interface SubscribeParty {
  data: SubscribePartyData
}

export interface SubscribePartyData {
  imageUploaded: Image
}

export interface UserInfo extends Data {
  maxCountParties: number
  countParties: number
  readDataProtect : {
    checked: boolean,
  }
}

export default class Party {
  @observable userInfo : UserInfo = null
  @observable all : AllParty[] = []
  @observable exactParty : Oneparty = null

  @action
  subscribeParty(id: string) {
    const obj = this // tslint:disable-line
    const res = getSubscribeResult<SubscribePartyData>(Subscribtions.getSubscribeParty, { id }).subscribe({
      next(data) {
        runInAction(() => {
          const nextp = obj.exactParty
          data.data.imageUploaded.url = `${getImageHandlerURL()}${data.data.imageUploaded.url}`
          nextp.images.push(data.data.imageUploaded)
          obj.exactParty = Object.assign({}, nextp)
        })
      },
      error(err) {
        console.error('Subscribe error ', err)
      },
    })
    return res
  }

  @action
  deleteParty(id : string) {
    getMutationResult<Data>(Mutations.deleteParty, { id }).then((e) => {
      this.loadAllParties()
    })
  }

  @action
  addNewParty(name :string) {
    getMutationResult<Data>(Mutations.addParty, { name }).then((e) => {
      runInAction(() => {
        this.loadAllParties()
      })
    })
  }

  @action
  loadPartyById(id: string) {
    getQueryResult<OnePartyData>(Queries.getPartyById, { id }).then((e) => {
      runInAction(() => {
        this.exactParty = e.data.oneparty
        this.exactParty.images.map(one => {
          one.url = `${getImageHandlerURL()}${one.url}`
          return one
        })
      })
    })
  }

  @action
  loadAllParties() {
    getQueryResult < {UserInfo: UserInfo}>(Queries.getAllParties).then((e) => {
      runInAction(() => {
        this.all = e.data.UserInfo.allParty
        this.userInfo = e.data.UserInfo
      })
    })
  }

  @action
  setRequiredDocumentsRead() {
    getMutationResult< {UserInfo: UserInfo}>(Mutations.requiredDocumentsRead).then((e) => {
      runInAction(() => {
        this.all = e.data.UserInfo.allParty
        this.userInfo = e.data.UserInfo
      })
    })
  }

}
