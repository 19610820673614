import { observable, action, runInAction } from 'mobx'
import  { ajax } from 'rxjs/ajax'
import {map} from 'rxjs/operators'
import {getAPIURL} from '../utils/helper'
export type response = {
  files: string[],
}

export default class Slides {
  @observable images:string[] = []

  @action
  getImages(id : string) {
    return ajax({
      url: `${getAPIURL()}/api/slides/${id}`,
      method: 'GET',
    }).pipe(
      map(val => {
        return val.response.files
      }),
      map(val => {
        return val.map(one => {
          return `${getAPIURL()}/${one}`
        })
      })
    )
  }

  @action
  loadImages(id:string) {
    ajax({
      url: `${getAPIURL()}/api/slides/${id}`,
      method: 'GET',
    }).subscribe((res) => {
      const slidesInfo : response = res.response
      runInAction(() => {
        this.images = slidesInfo.files
      })
    })
  }

}
