const de = {
  header: {
    title: 'Fasibio Authenticator Beta',
  },
  confirmRegistry: {
    confirmError: 'Mit der Bestätigung ging etwas schief',
    onConfirming: 'Dein Account wird registriert',
    confirmed: 'Registrierung abgeschlossen! Anmeldung ist nun möglich.',
    comingSoon: 'Browser Registrierung kommt noch...',
    useAppToRegistry: 'Benutze bitte die App um dich zu registrieren',
  },
  passwordReset: {
    error: 'Es fehlen wichtige Informationen...',
    diffPassword: 'die Passwörter unterscheiden sich',
    password: 'Passwort',
    confirmPassword: 'Passwort bestätigen',
    btnSetPassword: 'Passwort ändern',
    successfulChanged: 'Dein Passwort wurde erfolgreich geändert!',
    changePassword: 'Passwort ändern',
  },
  startPage: {
    email: 'Email',
    password: 'Passwort',
    login: 'Anmelden',
    noAccount: 'Noch keinen Account ?',
    registryBtn: 'erstellen',
    successLogin: 'YUHU Sie wurden erfolgreich angemeldet!',
    forgotPassword: 'Passwort vergessen ?',
  },

  registry: {
    title: 'Registrieren',
    email: 'Email-Adresse*',
    diffPassword: 'die Passwörter unterscheiden sich',
    password: 'Passwort*',
    confirmPassword: 'Passwort bestätigen*',
    registryButton: 'Registrieren',
    emailRequired: 'Sie müssen eine E-Mail Adresse angeben',
    registrySuccessful: 'Ihre Registrierung war erfolgreich. Schauen Sie in Ihrere E-Mails und betätigen ' +
        'Sie den Link, un die Registrierung abzuschließen',
  },
  forgotPassword: {
    title: 'Passwort vergessen',
    email: 'Email-Adresse*',
    sendButton: 'Absenden',
    successSend: 'Wir haben Ihnen eine E-Mail für das Passwort ändern gesendet',
  },
}
export default de
