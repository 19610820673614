import React from 'react'
import Header from '../Header'
import { History } from 'history'
import { inject, observer } from 'mobx-react'
import PartyHandler from '../../models/PartyHandler'
import UserHandler from '../../models/UserHandler'
import I18n from '../../config/locale'
import styled from 'styled-components'
import TabLinks from './Links'
import ImagePreview from './ImagePreview'
import PayDialog from './PayDialog'
import { PriorBtn } from '../atoms'
import CartHandler, { PayType } from '../../models/Cart'
import ProductAddedToCartDialog from './ProductAddedToCart'
const Content = styled.div`

`
interface IProps {
  user?: UserHandler
  party? : PartyHandler
  cart? : CartHandler
  history? : History
  location? : {
    state: {
      id: string,
    },
  }
}

interface IState{
  openPayDialog : boolean
}
@inject('party', 'user', 'cart')
@observer
export default class PartyPage extends React.Component<IProps, IState>{
  subscribeobservable : ZenObservable.Subscription = null
  constructor(props: IProps) {
    super(props)
    this.state = {
      openPayDialog: false,
    }
  }
  componentWillMount() {
    this.props.party.loadPartyById(this.props.location.state.id)
    this.subscribeobservable = this.props.party.subscribeParty(this.props.location.state.id)

  }

  componentWillUnmount() {
    this.subscribeobservable.unsubscribe()
  }

  payDialogCloseClicked = () => {
    this.setState({
      openPayDialog: false,
    })
  }
  openPayDialogClicked = () => {
    this.setState({
      openPayDialog: true,
    })
  }

  addToCartClicked = (payType: PayType) => {
    this.props.cart.payProduct({
      payType,
      partyID: this.props.location.state.id,
    })
  }

  render() {
    if (this.props.party.exactParty == null) {
      return (
        <>
        </>
      )
    }
    return(
      <>
        <Header
        onLogoutClicked={() => {
          this.props.user.logout()
        }}
        onBackClick={() => {
          this.props.history.goBack()
        }} showBackBtn={true} title={I18n.t('partyPage.title') + this.props.party.exactParty.name} />
        <Content>
          <PriorBtn onClick={this.openPayDialogClicked}>{I18n.t('partyPage.payBtn')}</PriorBtn>
          <ProductAddedToCartDialog />
          <PayDialog
          open={this.state.openPayDialog}
          closeClicked={this.payDialogCloseClicked}
          addToCartClicked={ this.addToCartClicked}
          />
          <TabLinks partyId={this.props.party.exactParty.ID}/>
          <ImagePreview images={this.props.party.exactParty.images}/>
        </Content>
      </>
    )

  }
}
