import { observable, action, runInAction } from 'mobx'
import * as Cookie from 'js-cookie'
type User = {
  name: string,
  username: string,
  exp: number,
}
export default class UserHandler{

  @observable user :User = null
  @observable accessToken: string = ''
  @observable triggerRefreshToken: boolean = false
  intervallid: number = null
  constructor() {
    this.loadDataFromCookie()
    this.startRefreshTrigger()
  }

  @action
  logout() {
    Cookie.remove('Authorization')
    window.location.href = window.location.origin
  }

  startRefreshTrigger() {
    this.intervallid = setInterval(() => {
      if (this.user == null) {
        return
      }
      const now = new Date()

      const diff = this.user.exp - now.getTime()
      if (diff < 120000) {
        runInAction(() => {
          this.triggerRefreshToken = true
        })
      } else {
        if (this.triggerRefreshToken) {
          this.triggerRefreshToken = false
        }
      }

    },                             3000)
  }

  @action
  loadDataFromCookie() {
    const authCookie = Cookie.get('Authorization')
    if (authCookie == null) {
      runInAction(() => {
        this.accessToken = ''
        this.user = null
      })
      return
    }
    const accessToken = authCookie.substr(6)
    const tokenarr = accessToken.split('.')
    const payload = window.atob(tokenarr[1])
    runInAction(() => {
      this.accessToken = accessToken
      const payloadObj = JSON.parse(payload)

      this.user = {
        name: payloadObj.name,
        username: payloadObj.preferred_username,
        exp: payloadObj.exp * 1000,
      }

    })
  }

}
