import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import BackSpace from '@material-ui/icons/ArrowBack'
import LogoutCircle from '@material-ui/icons/MeetingRoom'
import AccountCircle from '@material-ui/icons/AccountCircle'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import I18n from '../config/locale'
import ShoppingCart from './ShoppingCart'
import { inject, observer } from 'mobx-react'
import CartModel from '../models/Cart'
interface ToolbarProps{
}

const MyToolbar = styled(Toolbar as React.SFC<ToolbarProps>)`
justify-content: space-between;
`

const RightSideMenu = styled.div`
  display: flex;
`

interface IProps {
  title: string
  showBackBtn? : boolean
  onBackClick?: () => void
  onLogoutClicked?: () => void
  showMenu? : boolean,
  cart?: CartModel,
}

@inject('cart')
@observer
export default class Header extends React.Component<IProps, {}>{
  static defaultProps = {
    showMenu: true,
    showBackBtn: false,
    onBackClick: () => {},
  }
  openProfileSettingsInNewTab() {
    window.open('https://login.fasibio.de/auth/realms/Fasibio/account', '_blank')
  }

  openShoppingCartClicked = () =>  {
    if (this.props.cart !== undefined){
      this.props.cart.getPaymentRedirectUrl().then((url) => {
        window.location.href = url
      })
    }
    
  }

  render() {
    const BackBtn = this.props.showBackBtn ? (
      <IconButton onClick={this.props.onBackClick} color="inherit">
          <BackSpace />
      </IconButton>
    ) : null
    const right = this.props.showMenu ? (<RightSideMenu>
      <Tooltip title={I18n.t('header.openProfile')}>
        <IconButton onClick={this.openProfileSettingsInNewTab} color="inherit">
          <AccountCircle />
        </IconButton>
        </Tooltip>
        {this.props.cart !== undefined ?<ShoppingCart openShoppingCartClicked={this.openShoppingCartClicked} amount={this.props.cart.size}/>: null}
        
        <Tooltip title={I18n.t('header.logoutTooltip')}>
          <IconButton onClick={this.props.onLogoutClicked}>
            <LogoutCircle />
          </IconButton>
        </Tooltip>
      </RightSideMenu>) : null
    return (
      <AppBar position="static" color="default">

        <MyToolbar>
          {BackBtn}
          <Typography variant="title" color="inherit">
         {this.props.title}
          </Typography>
          {right}
        </MyToolbar>
      </AppBar>
    )
  }
}
