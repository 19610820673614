import { ApolloClient } from 'apollo-client'
import { WebSocketLink } from 'apollo-link-ws'
// import { SubscriptionClient } from 'subscriptions-transport-ws'
import { HttpLink } from 'apollo-link-http'
import { split, Observable, ApolloLink, FetchResult } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import {OperationDefinitionNode} from 'graphql'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import {getAPIURL, newTraceID} from '../utils/helper'

const SOCKET_ENDPOINT = window.location.protocol === 'http:' ? `ws://${getAPIURL(false)}/query` : `wss://${getAPIURL(false)}/query`

const wsLink = new WebSocketLink({
  uri: SOCKET_ENDPOINT,
  options: {
    reconnect: true,
  },
})

const httpLink = new HttpLink({
  credentials: 'same-origin',
  uri: `${getAPIURL()}/query`,
})

const authLink = setContext((obj1, obj) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('photomediausertoken');
  // return the headers to the context so httpLink can read them
  console.log(obj)
  return {
    headers: {
      'x-b3-traceid': newTraceID(),
      Authorization: token ? `Bearer ${token}` : "",
    },
  }
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation  } = getMainDefinition(query) as OperationDefinitionNode
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    link, 
  ]),
  cache: new InMemoryCache(),
})

type variables = {
  nothing?: boolean,
  [i: string]: any,
}

const iniVariables: variables = {
  nothing: true,
}

export function getSubscribeResult<T>(subscription: any, variables = iniVariables): Observable<FetchResult<T, Record<string, any>, Record<string, any>>> {
  return client.subscribe<T, variables>({
    variables,
    query: subscription,
  })
}

export function getMutationResult<T>(mutation: any, variables = iniVariables): Promise<FetchResult<T, Record<string, any>, Record<string, any>>>{
  return client.mutate<T, variables>({
    mutation,
    variables,

  })
}

export function getQueryResult<T>(query: any, variables = iniVariables) {
  return client.query<T, variables>({
    query,
    variables,
    fetchPolicy: 'network-only',
    // context: {
    //   headers: {
    //     "lala": "lal"
    //   }
    // }
  })
}
