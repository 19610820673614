import React from 'react'
import styled from 'styled-components'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Tooltip from '@material-ui/core/Tooltip'
import I18n from '../config/locale'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import { HalfSpace } from './atoms'
import copy from 'copy-to-clipboard'

interface Props{
}

interface BtnProps extends IconButtonProps{
  hasBorderRadiusRight?: boolean

}

const MyIconButton = styled(IconButton as React.SFC<BtnProps>)`
 && {
  border: 1px solid #dfdfdf;
  ${props => props.hasBorderRadiusRight ? `
  border-radius: 0px 5px 5px 0px;

  ` : `
  border-radius: 5px;
`
}
  left: -1px;
  :hover{
    background-color: #f0f0f0;
  }
 }

`
const MyOpenInNewIcon = styled(OpenInNewIcon as React.SFC<Props>)`
  && {

  }

`

const MyFileCopyIcon = styled(FileCopyIcon as React.SFC<Props>)`
  && {

  }

`

const LinkBox = styled.input`
min-width: 300px;
border: 1px solid #dfdfdf;
font-size: 16px;
font-family: "Roboto script=all rev=1";
border-radius: 5px 0px 0px 5px;
padding: 6px 10px;
`

const Parent = styled.div`
  display: flex;
`

interface IProps {
  linkUrl: string,

}

type State = {
  copiedToClipboard: boolean,
}

export default class ShareLinks extends React.Component<IProps, {}>{
  state: State = {
    copiedToClipboard: false,
  }
  input: HTMLInputElement = null

  onCopiedToClipboardClicked = () => {
    copy(this.props.linkUrl)
    this.setState({ copiedToClipboard: true })
    setTimeout(() => {
      this.setState({ copiedToClipboard: false })
    },         5000)
  }

  onOpenInNewTabClicked = () => {
    window.open(this.props.linkUrl, '_blank')
  }

  onClickInput = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.select()
  }

  render() {
    return(
      <Parent>
      <LinkBox onClick={this.onClickInput} ref={(r) => { this.input = r }} readOnly={true} type="input" value={this.props.linkUrl}/>

           <Tooltip title={this.state.copiedToClipboard ? I18n.t('shareLinks.clipboardTooltipCopied') : I18n.t('shareLinks.clipboardTooltip')}>
           <MyIconButton onClick={this.onCopiedToClipboardClicked} hasBorderRadiusRight={true} >
            <MyFileCopyIcon />
            </MyIconButton>
           </Tooltip>
         <HalfSpace />
         <Tooltip title={I18n.t('shareLinks.openInNewTab')}>
          <MyIconButton onClick={this.onOpenInNewTabClicked}>
          <MyOpenInNewIcon />
          </MyIconButton>
        </Tooltip>
      </Parent>
    )
  }
}
