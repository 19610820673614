import { observable, action } from 'mobx'
import { Queries } from '../config/GraphqlQueries'
import { getQueryResult  } from '../config/apolloSettings'
import { PayType } from './Cart'

export interface PayInfo {
  type:      PayType
  humenDesc: string
  price:     Price
}

export interface Price {
  price:    number
  currency: string
}
export default class PayHandler {

  @observable payInfos : PayInfo[] = null

  @action
  loadPayInfo  ()  {
    getQueryResult<{ payInfo: PayInfo[]}>(Queries.payInfo).then((e) => {

      this.payInfos = e.data.payInfo
    })
  }
}
