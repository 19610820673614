import React from 'react'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartRounded'
import IconButton from '@material-ui/core/IconButton'
import { Text } from './atoms'
import styled from 'styled-components'
import I18n from '../config/locale'
import Tooltip from '@material-ui/core/Tooltip'

interface IProps{
  amount: number
  openShoppingCartClicked: () => void
}

const BuyNumber = styled(Text)`
position: absolute;
z-index: 1;
font-size: 12px;
bottom: 30px;
padding: 0;
`


export default class ShoppingCart  extends React.Component<IProps> {
  render() {
    return (
            <Tooltip title={I18n.t('header.shoppingcartTooltip')}>

    <IconButton onClick={this.props.openShoppingCartClicked} color="inherit">
    <BuyNumber>{this.props.amount}</BuyNumber>
          <ShoppingCartIcon />
      </IconButton>
      </Tooltip>
    )
  }

}
