import { observable, action, runInAction } from 'mobx'
import  { ajax } from 'rxjs/ajax'
import I18n from '../config/locale'
import {getImageHandlerURL} from '../utils/helper'
type previewImage=  {
  file: string,
  isUploaded: boolean,
}
type fileUploadError = {
  errorCode: number,
  errorMsg: string,
}
export default class FileUpload{

  @observable uploadPreview: previewImage[] = []
  @observable fileUploadError : fileUploadError = null

  @action
  uploadFiles(files: File[], id : string) {
    files.forEach((one) => {
      const form = new FormData()
      const arraynum = this.uploadPreview.push({
        file: URL.createObjectURL(one),
        isUploaded: undefined,
      })
      form.append('uploadfile0', one)
      ajax({
        url:`${getImageHandlerURL()}/api/upload?count=1&party=${id}`,
        method: 'POST',
        body: form,
      })
      .subscribe((res) => {
        console.log('UPLOAD')

        if (res.status === 200) {
          runInAction(() => {
            this.uploadPreview[arraynum - 1].isUploaded = true
          })

        } else {
          this.uploadPreview[arraynum - 1].isUploaded = true
          console.log('hier', res.status)
          alert('Upload Failed !!!')
        }

      },         (err : any) => {

        runInAction(() => {
          if (err.status === 423) {
            this.uploadPreview[arraynum - 1].isUploaded = true
            this.fileUploadError = {
              errorCode: err.status,
              errorMsg: I18n.t('fileupload.error423'),
            }
          }
        })
          // server has not enough space for uploading image
      })

    })

  }
}
