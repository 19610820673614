import React from 'react'
import I18n from '../../config/locale'
import { Title, LongText } from '../atoms'
import styled from 'styled-components'
import Gallery from 'react-photo-gallery'
import { Image } from '../../models/PartyHandler'
const Root = styled.div`

`
interface IProps {
  images: Image[]
}

type ImageObj = {
  src: string,
  width: number,
  height: number,
}

export default class ImagePreview extends React.Component<IProps, {}>{

  getImageElement(img : Image):  ImageObj {
    if (img.width > img.height) {
      return {
        src: img.url,
        height: 3,
        width: 4,
      }
    }
    return {
      src: img.url,
      height: 4,
      width: 3,
    }
  }

  getImageSet() {
    return this.props.images.map((one) => {
      return this.getImageElement(one)
    })
  }

  render() {
    return (
      <Root>
        <Title>{I18n.t('partyPage.imagePreviewTitle')}</Title>
        <LongText>{I18n.t('partyPage.imagePreviewSubTitle')}</LongText>
        <Gallery photos={this.getImageSet()}  />
      </Root>
    )
  }
}
