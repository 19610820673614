export default class Login{

  getRefreshURI() {
    const response_type = encodeURIComponent('token')
    const client_id = 'photomedia'
    const redirect_uri = encodeURIComponent(window.location.origin + '/useraccess.html')
    const scope = encodeURIComponent('openid profile')
    const state = 'refresh'
    const nonce = 'n-0S6_WzA2Mj'

    const url = `https://login.fasibio.de/auth/realms/Fasibio/protocol/openid-connect/auth?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&nonce=${nonce}`
    return url
  }
  getLoginURI() {

    const response_type = encodeURIComponent('token')
    const client_id = 'photomedia'
    const redirect_uri = encodeURIComponent(window.location.origin + '/useraccess.html')
    const scope = encodeURIComponent('openid profile')
    const state = 'af0ifjsldkj'
    const nonce = 'n-0S6_WzA2Mj'

    const url = `https://login.fasibio.de/auth/realms/Fasibio/protocol/openid-connect/auth?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&nonce=${nonce}`
    return url
  }

}
