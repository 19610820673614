import uuid from 'uuid/v4'

type Vars  = {
  [index : string] : string,
}

export function getUrlVars(): Vars {
  const vars : Vars = {}
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,  (m, key :string, value: string)  => {
    vars[key] = value
    return ''
  })
  return vars
}

export function getParty(): string {
  return getUrlVars()['id']
}

export const newTraceID = () => {
 return uuid()
}

export const getImageHandlerURL = (http:boolean= true) => {
  let protokoll = ""
  if (http){
    protokoll = window.location.protocol + "//"
  }
  const res = "photomediaapiimageholder.fasibio.de"  // eslint-disable-line
  if (res.match("{{IMAGEHANDLERURL}}")){
    return `${protokoll}localhost:8083`
  }
  return `${protokoll}${res}`
}

export const getAPIURL = (http:boolean= true) => {
  let protokoll = ""
  if (http){
    protokoll = window.location.protocol + "//"
  }
  const res = "photomediaapi.fasibio.de"  // eslint-disable-line
  if (res.match("{{APIURL}}")){
    return `${protokoll}localhost:8080`
  }
  return `${protokoll}${res}`
}


