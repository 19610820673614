import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { PriorBtn, Space, Text } from './atoms'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import I18n from '../config/locale'
import styled from 'styled-components'

const CheckboxWrapper = styled.div`
display: flex;
`

interface IProps {
  open: boolean
  submitClicked: () => void
}

type State = {
  [index: string]: boolean,
  errorUnChecked: boolean,
}
export class RequiredDocumentsReadDialog extends React.Component<IProps, {}>{

  state : State

  constructor(props :IProps) {
    super(props)
    this.state = {
      dataProtectChecked: false,
      errorUnChecked: false,
    }
  }
  submitClicked = () => {
    if (this.state.dataProtectChecked) {
      this.props.submitClicked()
    } else {
      this.setState({
        errorUnChecked: true,
      })
    }
  }

  handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [name]: event.target.checked })
  }

  renderCheckBox(name: string) {

    let error = false
    if (!this.state[name]) {
      if (this.state.errorUnChecked) {
        error = true
      }
    }
    return (
      <CheckboxWrapper>
      <Checkbox
      checked={this.state[name]}
      onChange={this.handleChange(name)}
      title="TITLE"
      value={name}
      color="primary"

    />
      <Text error={error}>{I18n.t('requiredDocumentsReadDialog.descriptionDataProtectCheckbox1')}
      <a target="_blank" rel="noopener noreferrer" href={I18n.t('startPage.dataprotectURL')}>
        {I18n.t('startPage.dataprotection')}
      </a>
      {I18n.t('requiredDocumentsReadDialog.descriptionDataProtectCheckbox2')}
      </Text>
      </CheckboxWrapper>
    )
  }

  render() {
    return (
      <Dialog open={this.props.open} >
      <DialogTitle>
        {I18n.t('requiredDocumentsReadDialog.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {I18n.t('requiredDocumentsReadDialog.description')}

        </DialogContentText>
        <Space />
        {this.renderCheckBox('dataProtectChecked')}
      </DialogContent>
      <DialogActions>
        <PriorBtn onClick={this.submitClicked}>{I18n.t('requiredDocumentsReadDialog.submit')}</PriorBtn>
      </DialogActions>
    </Dialog>
    )
  }
}
