import React from 'react'
import { Text } from '../atoms'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import DeleteForever from '@material-ui/icons/DeleteForever'
import { AllParty } from '../../models/PartyHandler'
import I18n from '../../config/locale'
interface MyAvatarProps{
  bg: string
}

const MyAvatar = styled(Avatar as React.SFC<MyAvatarProps>)`
 && {
  margin-right: 6px;
  background-color: #${props => props.bg};
 }

`

const Root = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid gray;
  cursor: pointer;
  justify-content: space-between;
  :hover{
    background-color: #F5F5F5;
  }
`

interface MyDeleteForeverProps {}
const MyDeleteForever = styled(DeleteForever as React.SFC<MyDeleteForeverProps>)`
  &&{
    :hover{
      color: black;
    }
  }
`
const RightSide = styled.div``

const LeftSide = styled.div`
display: flex;
align-items: center;
flex:1;
`
const RightSideFromLeft = styled.div`
display: flex;
`
const LeftSideFromLeft = styled.div`
display: flex;
align-items: center;
flex:1;
`
interface IProps  {
  party: AllParty

  onClick : (ID: string) => void
  onClickDelete: (ID: string) => void
}

export default class Party extends React.Component < IProps, {} > {

  renderTextCircle = () => {
    return <MyAvatar bg={Math.floor(Math.random() * 16777215).toString(16)}>
        {this.props.party.name.substr(0, 2).toUpperCase()}
    </MyAvatar>
  }

  renderExpireDate() {

    const date = new Date(this.props.party.expireDate * 1000)
    return <Text>{I18n.t('home.expiredTime')}{date.toDateString()}</Text>
  }

  renderPartyUsedSize() {
    if (this.props.party.maxSize > 0) {
      return (
      <Text>
      {(this.props.party.folderSize / 1000 / 1000).toFixed(2)} MB /{this.props.party.maxSize / 1000 / 1000} MB
      </Text>
      )
    }
    return (
      <Text>
      {(this.props.party.folderSize / 1000 / 1000).toFixed(2)} MB
      </Text>
    )
  }

  render() {
    return (
    <Root >
    <LeftSide onClick={() => {
      this.props.onClick(this.props.party.ID)
    }}>
    <LeftSideFromLeft>
        {this.renderTextCircle()}
        <Text>{this.props.party.name}</Text>
      </LeftSideFromLeft>
      <RightSideFromLeft>
        {this.renderPartyUsedSize()}
        <Text>|</Text>
        {this.renderExpireDate()}
      </RightSideFromLeft>
    </LeftSide>
    <RightSide>

      <IconButton onClick={() => {
        this.props.onClickDelete(this.props.party.ID)
      }}>
        <MyDeleteForever />
      </IconButton>
    </RightSide>

    </Root>
    )

  }
}
