import React from 'react'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import I18n from '../../config/locale'
import { LongText, Space } from '../atoms'
import { QRCode } from 'react-qr-svg'
import FileUpload  from '../FileUpload'
import ShareLink from '../ShareLink'
import {getAPIURL} from '../../utils/helper'
interface IProps {
  partyId: string
}

const Root = styled.div`

`
interface MyTabProps{
  value: TabValue,
  label: any,
}
const MyTab = styled(Tab as React.SFC<MyTabProps>)`
  && {
    background-color: #FF8F00;
    border: 1px solid #FFFFFF;
    opacity: 0.5;
    border-radius: 4px;
    :hover{
      background-color: #FF6F00;
    }
  }
`

const MyQrCode = styled(QRCode)`
  min-width: 170px;
  width: 170px;
  padding: 10px;
`

const TabContent = styled.div`
  min-height: 230px;
`

const UploadDropBoxAndQrContainer = styled.div`
display: flex;
`

const UploadTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
enum TabValue {
  'upload', 'download', 'slideshow',
}

type State = {
  value: TabValue,
}

export default class Links extends React.Component<IProps, {}>{

  state : State = {
    value: TabValue.upload,
  }

  handleTabChange = (event: any, value: string) => {
    this.setState({
      value,
    })
  }

  getUploadLink = () => {
    return `${window.location.origin}/fileupload?id=${this.props.partyId}`
  }

  getDownloadLink = () => {
    return `${getAPIURL()}/api/downloadParty?party=${this.props.partyId}`
  }

  getSlideShowLink = () => {
    return `${window.location.origin}/slideshow?id=${this.props.partyId}`
  }

  onClickFileUpload = () => {
    window.open(this.getUploadLink(), '_blank')
  }

  renderDownloadTab = () => {
    return <ShareLink linkUrl={this.getDownloadLink()} />
  }

  renderSlideShowTab = () => {
    return <ShareLink linkUrl={this.getSlideShowLink()} />
  }

  renderUploadTab = () => {
    return (<UploadTabWrapper>
      <ShareLink linkUrl={this.getUploadLink()} />
       <Space />

<UploadDropBoxAndQrContainer>
      <FileUpload id={this.props.partyId}/>
      <Space />

      <MyQrCode
          onClick={this.onClickFileUpload}
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="Q"
          value={ this.getUploadLink()}
        />
        </UploadDropBoxAndQrContainer>
    </UploadTabWrapper>)
  }
  render() {
    return(
      <Root>
        <LongText>{I18n.t('partyPage.linksDescription')}</LongText>
        <Tabs
        value = {this.state.value}
        onChange={this.handleTabChange}
        TabIndicatorProps={{
          hidden: true,
        }}
        >
          <MyTab value={TabValue.upload} label={I18n.t('partyPage.fileUploadTabTitle')}/>
          <MyTab value={TabValue.download} label={I18n.t('partyPage.downloadTabTitle')}/>
          <MyTab value={TabValue.slideshow} label={I18n.t('partyPage.slideshowTabTitle')}/>
        </Tabs>
        <Space />
        <TabContent>
          {this.state.value === TabValue.upload && this.renderUploadTab()}
          {this.state.value === TabValue.download && this.renderDownloadTab()}
          {this.state.value === TabValue.slideshow && this.renderSlideShowTab()}
        </TabContent>

      </Root >
    )

  }
}
