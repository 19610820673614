import React from 'react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import { Text, TextBlock } from './atoms'
import { inject, observer } from 'mobx-react'
import fileUp from '../models/FileUpload'
import PartyHandler from '../models/PartyHandler'
import Snackbar from '@material-ui/core/Snackbar'
import I18n from '../config/locale'

interface IProps {
  fileupload?: fileUp,
  id: string
  party? : PartyHandler
}

type previewImage=  {
  file: string,
  isUploaded: boolean,
}

const MyDropzone = styled(Dropzone)`
border: 2px dashed #000000;
border-radius: 5px;
display: flex;
background: white;
cursor: pointer;
height: 370px;
width: 450px;
min-width: 260px;
min-width: 149px;
overflow: scroll;
flex-direction: column;

`

const DropzoneImages = styled.div`
flex-flow: row wrap;
padding: 20px 20px;
display: flex;
`

const ThumbImageInner = styled.div<ThumbImageProps>`
display: flex;
min-width: 0;
overflow: hidden;
display: block;
width: 120px;
height: 120px;
border-radius: 20px;
overflow: hidden;
margin: 5px;
${props => props.isUploaded ?
  'border: 2px solid green' : ''
  }

${props => !props.isUploaded ?
  `
  border: 2px solid red;
  opacity: 0.3;
  ` :''
  }

${props => props.isUploaded === undefined ?
  `
  border: 2px solid transparent;
  opacity: 0.3;
  ` : ''}
`

interface ThumbImageProps {
  isUploaded: boolean
}
const UploadText = styled(Text)`
background-color: #FF8F00;
padding: 6px;
border-radius: 0px 0px 5px 5px;
opacity: 0.5;
`
const UploadStatus = styled.div`
  text-align: center;
`

const ThumbImage = styled.img`

width: 200px;
height: 200px;
`

type State = {
  previewImages: previewImage[],
}

@inject('fileupload', 'party')
@observer
export default class PageFileUpload extends React.Component<IProps, {}> {

  onDrop = (acceptedFiles: File[] , rejectedFiles: File[]) =>  {
    const party = this.props.id
    if (party === undefined) {
      alert('No Party was found !!!')
      return
    }
    if (this.props.fileupload !== undefined){
      this.props.fileupload.uploadFiles(acceptedFiles, party)
    }
  }

  renderSnackBar = () => {
    if (this.props.fileupload === undefined){
      return null
    }
    const isOpen = this.props.fileupload.fileUploadError != null
    let msgText = ''
    if (this.props.fileupload.fileUploadError != null) {
      msgText = this.props.fileupload.fileUploadError.errorMsg
    }
    return (
    <Snackbar anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={isOpen}
    autoHideDuration={12000}
    message={<Text>{msgText}</Text>}
    >

    </Snackbar >

    )
  }

  render() {
    let countSuccessUploaded = 0
    if (this.props.fileupload === undefined){
      return null
    }
    const thumbs = this.props.fileupload.uploadPreview.map((one, key) => {
      let isUploaded = false
      if (one.isUploaded) {
        isUploaded = true
        countSuccessUploaded = countSuccessUploaded + 1
      } else {
        isUploaded = false
      }
      console.log('uploaded?', isUploaded)
      return (
        <ThumbImageInner isUploaded={isUploaded}  key={'thumbimg' + one.file}>
          <ThumbImage src={one.file}/>
        </ThumbImageInner>
      )
    })

    const dropzoneBody = thumbs.length > 0 ? thumbs :
    (
    <>
      <TextBlock>
        <Text>Hier können mit Klick auf das Viereck eins oder mehrere Bilder Hochgeladen werden.</Text >
        <br />
        <Text>Oder die Bilder einfach auf das Viereck per Drag and Drop ziehen</Text>
      </TextBlock>
    </>)

    return (
      <>
      <MyDropzone onDrop={this.onDrop}>
      <UploadStatus>
        {this.props.fileupload.uploadPreview.length > 0 ? countSuccessUploaded === this.props.fileupload.uploadPreview.length ? <UploadText>{I18n.t('fileupload.uploadFinish')}</UploadText> : <UploadText>{I18n.t('fileupload.uploadingText')}</UploadText> : null}
      </UploadStatus>
      <DropzoneImages>
        {dropzoneBody}
       </DropzoneImages>
      </MyDropzone>

      {this.renderSnackBar()}
      </>
    )
  }
}
