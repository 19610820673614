import React from 'react'
import Header from '../Header'
import styled from 'styled-components'
import Login from './Login'
import MySlider from '../react-slider/MySlider'
import Slider from '../../models/Slides'
import { inject, observer } from 'mobx-react'
import { History } from 'history'
import UserModel from '../../models/UserHandler'
import I18n from '../../config/locale'

const MyImage = styled.div<{background: string}>`
background-image:url('${props => props.background}');
position: absolute;
top:0;
right: 0;
left: 0;
bottom: 0;
background-repeat: no-repeat;
background-size: contain;
background-position: 50%;
`

const MainDiv = styled.div`
display: flex;
flex-direction: column;
`
const Content = styled.div`
padding: 25px;
border-radius: 4px;
box-sizing: border-box;
position: absolute;
top: 25vh;
left: 0;
right: 0;
bottom: 0;

`

interface IProps {
  slide?: Slider
  history?: History
  user?: UserModel
}

type State = {
  images: string[],
}

@inject('slide', 'user')
@observer
export default class StartPage extends React.Component<IProps, {}>{

  state: State
  constructor(props: IProps) {
    super(props)
    this.state = {
      images : [],
    }
  }

  getSlideingImage() {
    return this.state.images.map((one, key) => {
      return (
        <MyImage key={'myimage' + one} background= {one}/>
      )

    })
  }
  renderExampleSlideShow() {
    return (<MySlider
      slideElements={this.getSlideingImage()}
      slideInterval={4000}
      />)
  }

  componentDidMount() {
    if (this.props.user.user !== null) {
      this.props.history.push('/home')
      return
    }
    const id = 'exampleFolder'
    this.props.slide.getImages(id).subscribe((res) => {
      if (res !== null) {
        this.setState(
          { images: res },
        )
      }
      
    })
  }

  render() {
    return (
      <MainDiv>
        <Header  showMenu={false} title={I18n.t('startPage.title')} />
          {this.renderExampleSlideShow()}
        <Content>
        <Login/>
        </Content>
      </MainDiv>

    )
  }
}
